<template>
  <div class="cart_tr tr-mutiple add_discount" @click="showDiscount = !showDiscount">
    <div class="cart_td td-mutiple_title">
      <p class="mutiple_title txt-white">
        <span>
          優惠
        </span>
      </p>
    </div>
    <div class="cart_td td-add_inner" :class="{ active: showDiscount }">
      <div class="cart_tr">
        <div class="cart_td td-discount_choose">
          <input type="radio"
            @input="$emit('resetDiscount', $event, i)"
            v-model="item.discountData.type"
            :disabled="$route.name !== 'CartInfo'"
            value="1" :name="`radio_${i}`" :id="`discount_radio_${i}`">
            <label :for="`discount_radio_${i}`">
              <span></span>
              <p>優惠代碼</p>
            </label>
            <template v-if="item.discountData.type == 1">
              <input
              @blur="checkPromotionCode(item, i, item.code ? true : false)"
              v-model="item.discountData.discount.code"
              :readonly="$route.name !== 'CartInfo'"
              placeholder="優惠代碼"
              type="text" class="small">
            <input
              @blur="checkPromotionCode(item, i, item.code ? true : false)"
              v-model="item.discountData.discount.number"
              :readonly="$route.name !== 'CartInfo'"
              placeholder="若無券號則不用填寫"
              type="text" class="small number">
            <!-- 有這個優惠 -->
            <p
            v-if="item.discountData.discount.result"
            class="txt-greenlake"
            style="margin-left:10px;">{{item.discountData.discount.message}}</p>
            <!-- 沒有這個優惠 -->
            <p
            v-else
            class="txt-red" style="margin-left:10px;">{{item.discountData.discount.message}}</p>
          </template>
        </div>
      </div>
      <div class="cart_tr" v-if="!item.code">
        <div class="cart_td td-discount_choose">
          <input
            @input="$emit('resetDiscount', $event, i)"
            :disabled="$route.name !== 'CartInfo'"
             v-model="item.discountData.type"
             type="radio" value="2" :name="`radio_${i}`" :id="`coupon_radio_${i}`">
          <label :for="`coupon_radio_${i}`">
            <span></span>
            <p>折價券</p>
          </label>
          <template v-if="item.discountData.type == 2">
            <input
              v-model="item.discountData.coupon.value"
              type="text" class="choose_coupon small"
              placeholder="選擇現有折價券"
              readonly
              @click="getMemberCoupons(item, i)">
            <p
            v-if="item.discountData.coupon.result"
            class="txt-greenlake" style="margin-left:10px;">{{item.discountData.coupon.message}}</p>
            <p
            v-else
            class="txt-red" style="margin-left:10px;">{{item.discountData.coupon.message}}</p>
          </template>
        </div>
      </div>
      <div class="cart_tr" v-if="!item.code">
        <div class="cart_td td-discount_choose">
          <input
            @input="$emit('resetDiscount', $event, i)"
            :disabled="$route.name !== 'CartInfo'"
            v-model="item.discountData.type"
            type="radio" value="3" :name="`radio_${i}`" :id="`birthDay_radio_${i}`">
          <label :for="`birthDay_radio_${i}`">
            <span></span>
            <p>生日券</p>
          </label>
          <template v-if="item.discountData.type == 3">
            <input
              v-model="item.discountData.birthDay.value"
              type="text" class="choose_coupon small"
              placeholder="選擇現有生日券"
              readonly
              @click="getMemberBirthdayCoupons(item, i)">
            <p
            v-if="item.discountData.birthDay.result"
            class="txt-greenlake"
            style="margin-left:10px;">{{item.discountData.birthDay.message}}</p>
            <p
            v-else
            class="txt-red" style="margin-left:10px;">{{item.discountData.birthDay.message}}</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http/index';

export default {
  name: 'CartDiscount',
  props: ['itemsArray', 'item', 'i'],
  data() {
    return {
      showDiscount: false,
    };
  },
  methods: {
    /* 取得會員符合條件的生日券 */
    async getMemberBirthdayCoupons(item, index) {
      if (this.$route.name === 'CartInfo') {
        const form = {
          member_id: this.user.id,
          product_id: item.product_id,
          subTotal: item.beforeDiscountTotal,
          qty: item.qty,
          days: item.days ?? 0,
          abroad_at: item.abroad_start ?? null,
        };
        const result = requestApi('promotion.getMemberBirthdayCoupons', form);
        result.then((res) => {
          if (res.status) {
            this.$emit('openLightBox', true, index, res.data, 3);
          } else {
            this.$emit('memberCoupons', index, false, res.message, null, 3);
          }
        });
      }
    },
    /* 取得會員符合條件的折價券 */
    async getMemberCoupons(item, index) {
      if (this.$route.name === 'CartInfo') {
        const form = {
          member_id: this.user.id,
          product_id: item.product_id,
          subTotal: item.beforeDiscountTotal,
          qty: item.qty,
          days: item.days ?? 0,
          abroad_at: item.abroad_start ?? null,
        };
        const result = requestApi('promotion.getMemberCoupons', form);
        result.then((res) => {
          if (res.status) {
            this.$emit('openLightBox', true, index, res.data, 2);
          } else {
            this.$emit('memberCoupons', index, false, res.message, null, 2);
          }
        });
      }
    },
    /* 檢驗優惠代碼並取得優惠折扣 */
    async checkPromotionCode(item, index, inVisible = false) {
      if (item.discountData.discount.code) {
        const form = {
          code: item.discountData.discount.code,
          coupon_no: item.discountData.discount.number,
          price: item.sell_price,
          member_id: this.user.id,
          product_id: item.product_id,
          subTotal: item.beforeDiscountTotal,
          qty: item.qty,
          days: item.days ?? 0,
          abroad_at: item.abroad_start ?? null,
          market_type: inVisible === true ? 2 : 1,
        };
        const result = requestApi('promotion.checkPromotionCode', form);
        result.then((res) => {
          if (res.status) {
            const cart = {
              member_id: this.user.id,
              cart_id: item.id,
              discount_type: res.data.discount_type,
              type: 1,
              code: item.discountData.discount.code,
              discount: res.data.discount,
              coupon_no: item.discountData.discount.number,
              promotion_detail_id: res.data.promotion_detail_id,
              subTotal: item.beforeDiscountTotal,
            };
            const addPromotionEvent = requestApi('cart.addPromotionEvent', cart);
            addPromotionEvent.then((response) => {
              if (response.status) {
                this.$emit('promotionCode', index, true, res.data.promotion_name, res.data, item.discountData.discount.code);
              } else {
                this.$emit('promotionCode', index, false, response.message);
              }
            });
          } else {
            const cart = {
              member_id: this.user.id,
              cart_id: item.id,
            };
            const addPromotionEvent = requestApi('cart.addPromotionEvent', cart);
            addPromotionEvent.then((response) => {
              if (response.status) {
                this.$emit('promotionCode', index, false, res.message);
              } else {
                this.$emit('promotionCode', index, false, res.message);
              }
            });
          }
        });
      } else {
        const cart = {
          member_id: this.user.id,
          cart_id: item.id,
        };
        const addPromotionEvent = requestApi('cart.addPromotionEvent', cart);
        addPromotionEvent.then((response) => {
          if (response.status) {
            this.$emit('promotionCode', index, false, '');
          } else {
            this.$emit('promotionCode', index, false, '');
          }
        });
      }
      // }
    },
  },
  watch: {
    itemsArray: {
      handler() {
        this.itemsArray.forEach((item, i) => {
          if (item.code) {
            this.checkPromotionCode(item, i, true);
          }
        });
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
};
</script>
