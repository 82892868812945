<!-- eslint-disable max-len -->
<template ref="cartMain">
  <div class="cart_block mb50">
    <h4 class="txt-bold">購物車明細</h4>
    <!-- wifi機購物車 -->
    <div class="cart_area wifi_cart">
      <div class="cart_head">
        <div class="cart_tr txt-white">
          <div class="cart_td td-pic"><small>商品</small></div>
          <div class="cart_td td-name"><small>名稱</small></div>
          <div class="cart_td td-timezone txt-center"><small>租借區間</small></div>
          <div class="cart_td td-time txt-center"><small>時間</small></div>
          <div class="cart_td td-prize txt-center"><small>單價</small></div>
          <div class="cart_td td-count txt-center"><small>數量</small></div>
          <div class="cart_td td-total txt-center"><small>小計</small></div>
          <div class="cart_td td-del txt-center"><small>移除</small></div>
        </div>
      </div>

      <div class="cart_item_box">
        <div class="cart_item" v-for="(item, i) in itemsArray" :key="`item_${i}_${item && item.id}`">
          <!-- wifi機 SIM eSIM -->
          <template v-if="item.type === '出租品' || item.category === 'SIM卡' || item.category === 'eSIM'">
            <div class="cart_tr">
              <div class="cart_td td-pic">
                <div class="picbox">
                  <figure
                    class="cart_pic"
                    :style="`background-image: url(${item.cover});`">
                  </figure>
                </div>
              </div>
              <div class="cart_td td-name">
                <div class="tag small">{{item.category}}</div>
                <h6 class="txt-bold">{{item.product_name}}</h6>
                <div>
                  <small class="txt-greenlake">流量: {{item.flow_type}}</small>
                  <template v-if="item.mobile">
                    <br />
                    <small class="txt-greenlake">機種: {{item.mobile}}</small>
                  </template>
                </div>
              </div>
              <div class="cart_td td-timezone center rwd-wifi" data-rwd="租借區間">
                <input
                  type="text"
                  name="daterange"
                  class="cart_timezone small"
                  :value="`${item.abroad_start}- ${item.abroad_end}`"
                  readonly>
              </div>
              <div class="cart_td td-time center rwd-wifi" data-rwd="時間">
                <p>{{item.days}}天</p>
              </div>
              <div class="cart_td td-prize center rwd-wifi" data-rwd="單價">
                <div>
                  <p class="txt-redorange">
                    <vue-numeric read-only currency="$" separator="," :value="item.sell_price"></vue-numeric>
                    / 天</p>
                  <p class="txt-line-through" v-if="item.price">
                    <vue-numeric read-only currency="$" separator="," v-show="item.price > 0" :value="item.price"></vue-numeric>
                    <template v-if="item.price > 0">
                      / 天
                    </template>
                    </p>
                </div>
              </div>
              <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                <div class="cart_number" data-max="10">
                  <div
                    v-if="$route.name === 'CartInfo'
                    && !item.discountData.birthDay.result
                    && !item.discountData.discount.result
                    && !item.discountData.coupon.result
                    && !discountEvent.data"
                    class="minus" @click="minus(i)">-</div>
                  <input id="cart_number001" class="count p" type="text" :value="item.qty" readonly>
                  <div
                    v-if="$route.name === 'CartInfo'
                    && !item.discountData.birthDay.result
                    && !item.discountData.discount.result
                    && !item.discountData.coupon.result
                    && !discountEvent.data"
                   :style="`${!checkingStock ? '' : 'cursor: not-allowed'}`"
                    class="plus" @click="plus(i)">+</div>
                </div>
              </div>
              <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
                <div>
                  <p class="txt-redorange">
                    <vue-numeric read-only currency="$" separator="," :value="item.total"></vue-numeric>
                  </p>
                  <p class="txt-line-through" v-if="item.originTotal">
                    <vue-numeric read-only currency="$" separator="," :value="item.originTotal"></vue-numeric>
                  </p>
                </div>
              </div>
              <div
                v-if="$route.name === 'CartInfo'"
                class="cart_td td-del center rwd-wifi" data-rwd="移除"
                @click="remove(i, item.id)">
                <img class="cart_del" src="@/assets/img/shopping/icon_trash.svg" alt="">
              </div>
              <div
                v-else
                class="cart_td td-del center rwd-wifi">
              </div>
            </div>
            <!-- wifi機 加購、優惠、附加品 -->
            <CartPlus
              @plusTotalPrice="(total) => plusTotalPrice(total, i)"
              @updateQtyChangeValue="qtyChange = false"
              :item="item"
              :qtyChange="qtyChange"
              :itemKey="`wrap_${i}`"
              @checkEnoughStock="checkEnoughStock"
            />
            <!-- wifi機 優惠 -->
            <CartDiscount
              :itemsArray="itemsArray"
              :item="item"
              :i="i"
              @discountPrice="(v) => item.sell_price = v"
              @promotionCode="promotionCode"
              @memberCoupons="memberCoupons"
              @openLightBox="openLightBox"
              @resetDiscount="resetDiscount"/>
          </template>

          <template v-else>
              <!-- 商品 -->
            <div class="cart_tr">
              <div class="cart_td td-pic">
                <div class="picbox">
                  <figure class="cart_pic"
                    :style="`background-image: url(${item.cover});`"></figure>
                </div>
              </div>
              <div class="cart_td td-name">
                <div class="tag small">{{item.category}}</div>
                <h6 class="txt-bold">{{item.product_name}}</h6>
                <small class="txt-greenlake" v-show="item.color">顏色: {{item.color}}</small>
              </div>
              <div class="cart_td td-timezone center rwd-wifi" data-rwd="租借區間">
                  <p>-</p>
                  <!-- <input type="text"
                    name="daterange" class="cart_timezone small"
                    value="01/01/2018 - 01/15/2018" readonly> -->
              </div>
                <div class="cart_td td-time center rwd-wifi" data-rwd="時間">
                  <p>-</p>
                </div>
                <div class="cart_td td-prize center rwd-wifi" data-rwd="單價">
                  <div>
                    <p class="txt-redorange">
                      <vue-numeric read-only currency="$" separator="," :value="item.sell_price"></vue-numeric>
                    </p>
                    <p class="txt-line-through" v-if="item.price">
                      <vue-numeric read-only currency="$" separator="," v-show="item.price > 0" :value="item.price"></vue-numeric>
                    </p>
                  </div>
                </div>
                <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                  <div class="cart_number" data-max="10">
                    <div
                      v-if="$route.name === 'CartInfo' && !item.discountData.discount.result && !item.discountData.coupon.result"
                      class="minus" @click="minus(i)">-</div>
                    <input id="cart_number001" class="count p" type="text" :value="item.qty" readonly>
                    <div
                      v-if="$route.name === 'CartInfo' && !item.discountData.discount.result && !item.discountData.coupon.result"
                      :style="`${!checkingStock ? '' : 'cursor: not-allowed'}`"
                      class="plus" @click="plus(i)">+</div>
                  </div>
                </div>
                <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
                  <div>
                    <p class="txt-redorange">
                      <vue-numeric read-only currency="$" separator="," :value="item.total"></vue-numeric>
                    </p>
                    <p class="txt-line-through" v-if="item.originTotal">
                      <vue-numeric read-only currency="$" separator="," :value="item.originTotal"></vue-numeric>
                    </p>
                  </div>
                </div>
                <div
                  v-if="$route.name === 'CartInfo'"
                  class="cart_td td-del center rwd-wifi" data-rwd="移除"
                  @click="remove(i, item.id)">
                  <img class="cart_del" src="@/assets/img/shopping/icon_trash.svg" alt="">
                </div>
                <div
                  v-else
                  class="cart_td td-del center rwd-wifi">
                </div>
            </div>
            <!-- 商品 優惠 -->
            <CartDiscount
              :itemsArray="itemsArray"
              :item="item"
              :i="i"
              @promotionCode="promotionCode"
              @memberCoupons="memberCoupons"
              @openLightBox="openLightBox"
              @resetDiscount="resetDiscount"/>
          </template>

        </div>
      </div>

      <!-- 折價券lightbox -->
      <div id="coupon_lightbox" class="" v-show="couponLightbox">
        <div class="cover" onclick="lightbox.close('coupon_lightbox')"></div>
        <div class="box">
            <img class="close" src="@/assets/img/icon/icon_X.svg" @click="closeLightBox(false)">
            <div class="box_in">
                <p>共有 <span class="txt-redorange">{{coupons.length}}</span> 張{{typeName}}符合使用規範</p>
                <div class="coupon_head">
                    <div class="coupon_tr txt-white">
                        <div class="coupon_td td-choose txt-center"><small>選取</small></div>
                        <div class="coupon_td td-name"><small>{{typeName}}名稱</small></div>
                        <div class="coupon_td td-exist"><small>有效日期</small></div>
                        <div class="coupon_td td-prize txt-right"><small>{{typeName}}面額</small></div>
                    </div>
                </div>
                <div class="coupon_item_box">
                    <div class="coupon_item" v-for="(coupon, i) in coupons" :key="`coupon_${i}`">
                      <div class="coupon_tr">
                          <div class="coupon_td td-choose txt-center" data-rwd="選取">
                              <input type="radio" v-model="selectCouponIndex" :value="i" name="selectCoupon_radio" :id="`selectCoupon_radio_${i}`">
                              <label :for="`selectCoupon_radio_${i}`"><span></span></label>
                          </div>
                          <div class="coupon_td td-name" data-rwd="折價券名稱">
                              <label for="coupon<?= $i ?>" style="cursor: pointer;">
                                  <p>{{coupon.event_name}}</p>
                              </label>
                          </div>
                          <div class="coupon_td td-exist" data-rwd="有效日期">
                              <p>{{dateYMD(coupon.expire_day)}}</p>
                          </div>
                          <div class="coupon_td td-prize txt-right" data-rwd="折價券面額">
                              <template v-if="coupon.discount_type === 1">
                                <p class="txt-redorange">${{coupon.discount}}</p>
                              </template>
                              <template v-else-if="coupon.discount_type === 2">
                                <p class="txt-redorange">{{coupon.discount}}%</p>
                              </template>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="btnset">
                    <button type="button" class="btn btn-grey" @click="closeLightBox(false)">
                        <p>取消</p>
                    </button>
                    <button type="button" class="btn btn-blue_gradient" @click="closeLightBox(true)">
                        <p>送出</p>
                    </button>
                </div>
            </div>
        </div>
      </div>

      <div class="cart_method">
        <!-- 選擇取件方式 -->
        <div class="method_choose">
          <h6 class="txt-bold txt-white">※租借品及出售品將合併寄送-依 WIFi 分享器出貨日為準</h6>
          <div class="method_item" v-if="this.currentCartType === 1">
            <p class="txt-white">航班時間</p>
            <select
              v-model="cartForm.flight_abroad"
              :disabled="$route.name !== 'CartInfo'"
              @input="flightAbroad($event)" class="small">
                <option value="null">請選擇</option>
                <option
                  v-for="(hour, i) in hourIntervalOptions"
                  :key="`hour_${i}`"
                  :value="hour.value">{{hour.text}}
                </option>
            </select>
          </div>
          <div class="method_item">
            <p class="txt-white">取件方式</p>
              <select
                v-model="cartForm.take_option_id"
                :disabled="$route.name !== 'CartInfo'"
                @input="selectTakeWays($event)"
                class="small">
                  <option value="null">請選擇</option>
                  <option
                  v-for="(location, i) in takeways"
                  :key="`take_${i}`"
                  :disabled="location.disable"
                  :value="location.id">
                    {{location.text}}
                  </option>
              </select>
            <div id="method_getdate-wifi" class="method_date wifi small" v-if="this.currentCartType === 1">
                取件日 {{takeDay}}
            </div>
          </div>
          <div class="method_item" v-if="this.cartsHasRent === 1">
            <p class="txt-white">歸還方式</p>
            <select
              :disabled="$route.name !== 'CartInfo'"
              v-model="cartForm.return_option_id"
              @input="returnTakeWays($event)"
              class="small">
                <option value="null">請選擇</option>
                <option
                  v-for="(location, i) in returnways"
                  :key="`return_${i}`"
                  :disabled="location.disable"
                  :value="location.id">
                    {{location.text}}
                </option>
            </select>
            <div id="method_getdate-wifi" class="method_date wifi small">
              歸還日 {{returnDay}}
            </div>
          </div>
          <div class="method_item">
            <p class="txt-white">運費</p>
            <div class="fake_input small txt-orange"
            v-if="delivery.data">
              單筆訂單滿${{delivery.data}}免運費
              <img src="@/assets/img/icon/icon_exclamation-o.svg" alt="" style="width: .65em;">
            </div>
            <small class="txt-white method_text">
              <span class="p txt-bold"
              >${{computedDeliveryPrice}}
              </span>
              <template
                v-if="delivery.message
                && computedDeliveryPrice > 0">
                ({{delivery.message}})
              </template>
            </small>
          </div>
          <!-- 新增免運優惠 -->
          <!-- <div class="method_item free_transport active">
              <p class="txt-white">免運優惠</p>
              <div class="add_img">
                <input type="text" class="choose_coupon small"
                  value="選擇現有免運優惠(餘5次)" readonly=""
                  onclick="lightbox.open('free_lightbox')">
                   <img src="@/assets/img/icon/icon_next-g.svg" alt="">
              </div>
          </div> -->
        </div>
        <!-- 運費 -->
        <!-- <div class="method_shipping">
            <p class="txt-white">免運費</p>
        </div> -->
      </div>
    </div>
    <!-- 新增加碼優惠bar -->
    <div class="add_bar" v-if="discountEvent">
      <div class="left">
        <span class="txt-orange">加碼
          <img src="@/assets/img/icon/icon_exclamation-o.svg" alt="" style="width: .65em;">全館優惠
        </span>
        <div class="discount  active">
          <input
          v-show="$route.name === 'CartInfo'"
          type="text" class="small" value="" placeholder="請輸入全館折扣碼" v-model="discountEvent.value" @blur="checkDiscountEvent($event)">
          <span v-if="discountEvent.result" class="txt-greenlake discount_text">{{discountEvent.message}}</span>
          <span v-else class="txt-red discount_text">{{discountEvent.message}}</span>
        </div>
      </div>
      <div class="discount_money txt-orange active" v-if="discountEvent.data">
          全館折扣
          <template v-if="discountEvent.data.discount_type == 1">
            -${{discountEvent.data.discount}}
          </template>
          <template v-else>
            {{discountEvent.data.discount}}%
          </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import localStorage from 'localStorage';
import moment from 'moment';
import requestApi from '@/lib/http/index';
import CartDiscount from '@/components/cart/CartDiscount.vue';
import CartPlus from '@/components/cart/CartPlus.vue';
// eslint-disable-next-line no-unused-vars
import { cloneDeep } from '../../lib/lodash';

// const { $ } = window;

/** 航班時間選單 */
const hourIntervalOptions = [
  {
    text: '23:00~01:00',
    value: '23:00~01:00',
  },
  {
    text: '01:00~03:00',
    value: '01:00~03:00',
  },
  {
    text: '03:00~05:00',
    value: '03:00~05:00',
  },
  {
    text: '05:00~07:00',
    value: '05:00~07:00',
  },
  {
    text: '07:00~09:00',
    value: '07:00~09:00',
  },
  {
    text: '09:00~11:00',
    value: '09:00~11:00',
  },
  {
    text: '11:00~13:00',
    value: '11:00~13:00',
  },
  {
    text: '13:00~15:00',
    value: '13:00~15:00',
  },
  {
    text: '15:00~17:00',
    value: '15:00~17:00',
  },
  {
    text: '17:00~19:00',
    value: '17:00~19:00',
  },
  {
    text: '19:00~21:00',
    value: '19:00~21:00',
  },
  {
    text: '21:00~23:00',
    value: '21:00~23:00',
  },
];

export default {
  name: 'CartMain',
  props: ['ifPrev', 'allPlusQty'],
  components: {
    CartDiscount,
    CartPlus,
  },
  data() {
    return {
      init: false,
      countIng: false,
      checkingStock: false,

      itemsArray: [],
      itemDiscountTotal: 0,
      itemOriginTotal: 0,
      itemQtyTotal: 0,

      cartForm: {
        flight_abroad: null,
        take_way_id: null,
        take_option_id: null,
        return_way_id: null,
        return_option_id: null,
      },
      cartsHasRent: null,
      currentCartType: null,
      discountEvent: {
        value: null,
        result: false,
        message: null,
        data: null,
      },
      beforeOriginTotal: null,
      takeways: [],
      returnways: [],
      takeDay: null,
      returnDay: null,
      hourIntervalOptions,
      showDiscount: false,
      couponLightbox: false,
      coupons: [],
      currentLightBoxItemIndex: null,
      selectCouponIndex: null,
      type: null,
      delivery: {
        message: null,
        status: false,
      },
      afterDiscount: {},
      qtyChange: false,
    };
  },
  computed: {
    ...mapState([
      'isLogin',
      'user',
      'totalPriceAndCount',
      'abroad_at',
    ]),
    /* 取得運費價格 */
    computedDeliveryPrice() {
      if (!this.delivery.status && this.cartForm.take_option_id) {
        const { shipping_fee: sf = 0 } = this.takeways
          .find(({ id }) => id === this.cartForm.take_option_id) ?? {};
        return sf;
      }
      return 0;
    },
    /* 取得加購品總金額 */
    getcurrentPlusTotalPriceAndCount() {
      return this.itemsArray.filter(({ plusTotal = 0 }) => parseInt(plusTotal, 10) > 0)
        .map(({ plusTotal }) => plusTotal)
        .reduce((total, itemTotal) => total + itemTotal, 0);
    },
    /* 原始訂單總金額 */
    getoriginalTotalPriceAndCount() {
      return this.itemOriginTotal + this.computedDeliveryPrice
        + this.getcurrentPlusTotalPriceAndCount;
    },
    /* 取得無運費及再折扣價格 */
    getBeforeDeliveryPrice() {
      if (!this.init) return 0;

      return this.itemDiscountTotal + this.getcurrentPlusTotalPriceAndCount;
    },
    // 主商品
    getcurrentTotalPriceAndCount() {
      const count = this.itemsArray.length || 0;
      let total = this.itemDiscountTotal;
      const totalQty = this.itemQtyTotal;
      // if (this.itemsArray.length > 0) {
      //   count = this.itemsArray.length;
      //   this.itemsArray.forEach((item) => {
      //     total += item.total;
      //     totalQty += item.qty;
      //   });
      // }

      total = total - this.getAfterDiscount
        + this.computedDeliveryPrice
        + this.getcurrentPlusTotalPriceAndCount ?? 0;
      return {
        count,
        total,
        totalQty,
        shipping_fee: this.computedDeliveryPrice,
        /* 結帳金額再折扣資料 */
        afterDiscount: this.afterDiscount,
        /* 再折扣金額 */
        discount_code_amount: this.getAfterDiscount,
        /* 總折扣金額 */
        discount: this.getoriginalTotalPriceAndCount - total,
      };
    },
    /* 取得結帳金額再折扣金額 */
    getAfterDiscount() {
      let disountTotal = 0;
      const { data } = this.discountEvent;
      if (data) {
        const { discount } = data;
        if (parseInt(data.discount, 10) > 0) {
          // 1 固定金額
          if (parseInt(data.discount_type, 10) === 1) {
            disountTotal = discount;
          // 2 %數折扣
          } else if (data.discount_type === 2) {
            const caculateDiscount = (parseInt(discount, 10) > 0)
              ? (100 - discount) / 100 : 1;
            disountTotal = this.getBeforeDeliveryPrice
            - Math.round(this.getBeforeDeliveryPrice * caculateDiscount);
          }
        }
      }
      return disountTotal;
    },
    typeName() {
      let typeName = null;
      if (this.type === 2) {
        typeName = '折價券';
      } else if (this.type === 3) {
        typeName = '生日券';
      }
      return typeName;
    },
    getCurrentTakeDay() {
      let date = null;
      let ifRent = false;
      this.itemsArray.forEach((item) => {
        if (item.type === '出租品') {
          ifRent = true;
        }
      });
      if (ifRent) {
        this.itemsArray.forEach((item) => {
          if (item.type === '出租品') {
            if (item.abroad_end && item.abroad_start) {
              if (date) {
                if (
                  new Date(date).getTime() / 1000 > new Date(item.abroad_start).getTime() / 1000
                ) {
                  date = item.abroad_start;
                }
              } else {
                date = item.abroad_start;
              }
            }
          }
        });
      } else {
        this.itemsArray.forEach((item) => {
          if (item.abroad_end && item.abroad_start) {
            if (date) {
              if (new Date(date).getTime() / 1000 > new Date(item.abroad_start).getTime() / 1000) {
                date = item.abroad_start;
              }
            } else {
              date = item.abroad_start;
            }
          }
        });
      }
      return date;
    },
    getCurrentTakewayParent() {
      let data = null;
      this.takeways.forEach((item) => {
        if (item.id === this.cartForm.take_option_id) {
          data = item.parent_id;
        }
      });
      return data;
    },
    getCurrentReturnwayParent() {
      let data = null;
      this.returnways.forEach((item) => {
        if (item.id === this.cartForm.return_option_id) {
          data = item.parent_id;
        }
      });
      return data;
    },
    getReturnTakeDay() {
      let date = null;
      let ifRent = false;
      this.itemsArray.forEach((item) => {
        if (item.type === '出租品') {
          ifRent = true;
        }
      });
      if (ifRent) {
        this.itemsArray.forEach((item) => {
          if (item.type === '出租品') {
            if (item.abroad_end && item.abroad_start) {
              if (date) {
                if (new Date(date).getTime() / 1000 > new Date(item.abroad_end).getTime() / 1000) {
                  date = item.abroad_end;
                }
              } else {
                date = item.abroad_end;
              }
            }
          }
        });
      } else {
        this.itemsArray.forEach((item) => {
          if (item.abroad_end && item.abroad_start) {
            if (date) {
              if (new Date(date).getTime() / 1000 > new Date(item.abroad_end).getTime() / 1000) {
                date = item.abroad_end;
              }
            } else {
              date = item.abroad_end;
            }
          }
        });
      }
      return date;
    },
    getProductIds() {
      const ids = [];
      if (this.itemsArray.length > 0) {
        this.itemsArray.forEach((item) => {
          ids.push(item.product_id);
        });
      }
      return ids;
    },
  },
  watch: {
    // allPlusQty: {
    //   handler(v) {
    //     console.log('v', v);
    //   },
    //   deep: true,
    // },
    itemsArray: {
      deep: true,
      handler(val) {
        this.setItemValue(val);
      },
    },
    cartsHasRent(val, oldVal) {
      if (this.$route.name === 'CartInfo' && !this.ifPrev) {
        if (val !== oldVal) {
          this.cartForm = {
            flight_abroad: null,
            take_way_id: null,
            take_option_id: null,
            return_way_id: null,
            return_option_id: null,
          };
        }
      }
    },
    // checkCurrentCarts(val, oldVal) {
    //   if (this.$route.name === 'CartInfo') {
    //     if (val !== oldVal) {
    //       this.cartForm = {
    //         flight_abroad: null,
    //         take_way_id: null,
    //         take_option_id: null,
    //         return_way_id: null,
    //         return_option_id: null,
    //       };
    //     }
    //   }
    // },
    getCurrentTakewayParent(val) {
      this.takeWayId(val);
    },
    getCurrentReturnwayParent(val) {
      this.returnWayId(val);
    },
    getBeforeDeliveryPrice: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.checkFreightEvent();
        }
      },
      deep: true,
      // immediate: true,
    },
    getProductIds: {
      immediate: true,
      handler(ids) {
        if (ids.length) {
          this.getFilter(ids);
        }
      },
    },
  },
  methods: {
    ...mapActions('moduleCart', {
      actionHandleCartLoading: 'handleCartLoading',
    }),
    ...mapMutations([
      'setTakeway',
      'setAbroadAt',
      'setBackhomeAt',
      'setOrderDetail',
      'setTotalPriceAndCount',
      'setCart',
      'updateCanCartToDetail',
    ]),
    async checkStock(callback, falseCallback) {
      const lists = this.itemsArray;
      const listRequest = lists.map((product) => (this.checkEnoughStock(product)));
      const listResult = await Promise.all(listRequest);
      const errorProducts = listResult.filter(({ status }) => !status)
        .map((item) => ({
          id: item.product.id,
          name: item.product.product_name,
        }));

      // 若有 error
      if (errorProducts.length) {
        const errReq = errorProducts.map((product) => (this.remove(null, product.id)));
        const errResult = await Promise.all(errReq);

        if (errResult.length === errorProducts.length) {
          this.$customSWAL({
            icon: 'error',
            title: '庫存不足',
            text: errorProducts.map((item) => item.name).join('、'),
            confirmButtonText: '確定',
          });
          if (falseCallback) falseCallback();
        }
        return;
      }

      if (callback) callback();

      // lists.forEach((product, index) => {
      //   this.checkEnoughStock(product).then(({ status }) => {
      //     processed.push(product.product_id);

      //     // 若庫存不足 => push到errorProducts + 刪掉
      //     if (!status) {
      //       errorProducts.push(product.product_name);
      //       this.remove(index, product.id);
      //     }

      //     // 若全部檢查完 && 有error => 跳提示
      //     if (processed.length === lists.length) {
      //       if (errorProducts.length) {
      //         this.$customSWAL({
      //           icon: 'error',
      //           title: '庫存不足',
      //           text: errorProducts.toString(),
      //           confirmButtonText: '確定',
      //         });
      //         return;
      //       }
      //       // 若有 callback 則執行
      //       if (callback) callback();
      //     }
      //   });
      // });
    },
    setItemValue(lists) {
      const { originTotal = 0, discountTotal = 0, qtyTotal = 0 } = lists
        .map(({ beforeDiscountTotal, total, qty }) => ({ beforeDiscountTotal, total, qty }))
        .reduce((res, { beforeDiscountTotal = 0, total = 0, qty = 0 }) => ({
          originTotal: (res.originTotal || 0) + beforeDiscountTotal,
          discountTotal: (res.discountTotal || 0) + total,
          qtyTotal: (res.qtyTotal || 0) + qty,
        }),
        {});

      this.itemOriginTotal = originTotal;
      this.itemDiscountTotal = discountTotal;
      this.itemQtyTotal = qtyTotal;
    },
    plusTotalPrice(total, index) {
      this.itemsArray[index].plusTotal = total;
    },
    /* 結帳金額再折扣 */
    checkDiscountEvent(e) {
      const form = {
        code: e.target.value,
        member_id: this.user.id,
        // total_amount: this.getcurrentTotalPriceAndCount.total,
        total_amount: this.itemDiscountTotal,
        // qty: this.getcurrentTotalPriceAndCount.totalQty,
        qty: this.itemQtyTotal,
        days: this.getcurrentTotalPriceAndCount.days ?? 0,
        abroad_at: this.abroad_at ?? null,
      };
      const result = requestApi('promotion.checkDiscountEvent', form);
      result.then((res) => {
        if (res.status) {
          if (res.data) {
            if (
              /* 排除重複 */
              !this.discountEvent?.data
              || (this.discountEvent.data.event_name !== res.data.event_name)) {
              this.discountEvent = {
                value: e.target.value,
                result: true,
                message: res.data.event_name,
                data: res.data,
              };
              this.afterDiscount = res.data;
              const totalForm = this.getcurrentTotalPriceAndCount;
              this.afterDiscount.code = e.target.value;
              localStorage.setItem('discountEventCode', e.target.value);
              totalForm.total = Math.round(totalForm.total);
              this.setTotalPriceAndCount(totalForm);
            }
          }
        } else {
          this.discountEvent = {
            value: e.target.value,
            result: false,
            message: res.message,
            data: null,
          };
          const totalForm = this.getcurrentTotalPriceAndCount;
          localStorage.removeItem('discountEventCode', e.target.value);
          this.setTotalPriceAndCount(totalForm);
        }
      });
    },
    /* 更換折價券類型並重設欄位 */
    resetDiscount(e, index) {
      // 1:優惠代碼, 2:折價券 3:生日券
      const { value } = e.target;
      // 恢復原始價格
      this.itemsArray[index].total = this.itemsArray[index].beforeDiscountTotal;
      this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
      // 移除設定的code
      this.itemsArray[index].setCode = null;
      // 重設欄位
      this.itemsArray[index].discountData = {
        type: value,
        coupon: {
          value: null,
          result: false,
          message: null,
          data: null,
        },
        discount: {
          code: null,
          number: null,
          result: false,
          message: null,
          data: null,
        },
        birthDay: {
          code: null,
          result: false,
          message: null,
          data: null,
        },
      };
    },
    /* 更新折價券、生日券驗證結果 */
    memberCoupons(index, status, message, data = null, type) {
      /* 如果代碼查詢結果為成功 */
      if (status) {
        if (data.discount_type === 1) {
          this.itemsArray[index].total = this.itemsArray[index].beforeDiscountTotal
          - data?.discount ?? 0;
        } else {
          const discount = this.getDiscount(
            this.itemsArray[index].beforeDiscountTotal, data?.discount,
          );
          this.itemsArray[index].total = this.itemsArray[index].beforeDiscountTotal
          - discount ?? 0;
        }
        /* 出租品免費天數 */
        if (data?.free_day && parseInt(data.free_day, 10) > 0) {
          this.itemsArray[index].total
          -= (this.itemsArray[index].sell_price * parseInt(data.free_day, 10));
        }
        this.itemsArray[index].discountData.coupon.data = data;
        this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
      }
      if (type === 2) {
        this.itemsArray[index].discountData.coupon.result = status;
        this.itemsArray[index].discountData.coupon.message = message;
      } else {
        this.itemsArray[index].discountData.birthDay.result = status;
        this.itemsArray[index].discountData.birthDay.message = message;
      }
    },
    /* 取得優惠代碼驗證結果 */
    promotionCode(index, status, message, data = null, code) {
      /* 如果代碼查詢結果為成功 */
      if (status) {
        // if (data.discount_type === 1) {
        //   this.itemsArray[index].total = this.itemsArray[index].beforeDiscountTotal
        //   - data?.discount ?? 0;
        // } else {
        //   const discount = this.getDiscount(
        //     this.itemsArray[index].beforeDiscountTotal, data?.discount,
        //   );
        //   this.itemsArray[index].total = this.itemsArray[index].beforeDiscountTotal
        //   - discount ?? 0;
        // }
        // /* 出租品免費天數 */
        // if (data?.free_day && parseInt(data.free_day, 10) > 0) {
        //   this.itemsArray[index].total
        //   -= (this.itemsArray[index].sell_price * parseInt(data.free_day, 10));
        // }
        /* 確認是否設定重複的代碼 */
        if (code !== this.itemsArray[index].setCode) {
          this.itemsArray[index].discountData.discount.data = data;
          if (parseInt(data.result_money, 10) >= 0) {
            this.itemsArray[index].setCode = code;
            this.itemsArray[index].total = data.result_money;
          }
          this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
        }
      } else {
        /* 如果錯誤,重設價錢 */
        this.resetPrice(index);
      }
      this.itemsArray[index].discountData.discount.result = status;
      this.itemsArray[index].discountData.discount.message = message;
    },
    resetPrice(index) {
      this.itemsArray[index].setCode = null;
      this.itemsArray[index].total = this.itemsArray[index].beforeDiscountTotal;
      this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
    },
    getDiscount(total, discount) {
      return total
      * ((parseInt(discount, 10) > 0) ? (parseInt(discount, 10) / 100) : 1);
    },
    flightAbroad(e) {
      this.cartForm.flight_abroad = e.target.value;
      localStorage.setItem('order', JSON.stringify(this.cartForm));
      this.updateCanCartToDetail(
        {
          cartForm: this.cartForm,
          cartsHasRent: this.cartsHasRent,
        },
      );
    },
    returnWayId(v) {
      this.cartForm.return_way_id = v;
      localStorage.setItem('order', JSON.stringify(this.cartForm));
      this.updateCanCartToDetail(
        {
          cartForm: this.cartForm,
          cartsHasRent: this.cartsHasRent,
        },
      );
    },
    takeWayId(v) {
      this.cartForm.take_way_id = v;
      localStorage.setItem('order', JSON.stringify(this.cartForm));
      this.updateCanCartToDetail(
        {
          cartForm: this.cartForm,
          cartsHasRent: this.cartsHasRent,
        },
      );
    },
    selectTakeWays(e) {
      this.takeWayMethod(e.target.value);
      this.cartForm.take_option_id = e.target.value;
      localStorage.setItem('order', JSON.stringify(this.cartForm));
      this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
      this.updateCanCartToDetail(
        {
          cartForm: this.cartForm,
          cartsHasRent: this.cartsHasRent,
        },
      );
    },
    returnTakeWays(e) {
      this.cartForm.return_option_id = e.target.value;
      this.returnDay = this.getReturnTakeDay;
      localStorage.setItem('order', JSON.stringify(this.cartForm));
      this.updateCanCartToDetail(
        {
          cartForm: this.cartForm,
          cartsHasRent: this.cartsHasRent,
        },
      );
    },
    async remove(index, id) {
      const result = await requestApi('cart.deleteItem', { id });
      if (result.status) {
        const target = this.itemsArray.findIndex((item) => item.id === id);
        const idx = `${target}` || `${index}`;
        this.itemsArray.splice(idx, 1);
        this.setCart(this.itemsArray.length);
        this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
        this.checkCurrentCarts();
        this.checkCartsHasRent();
        return true;
      }
      this.$customSWAL({
        icon: 'error',
        title: '錯誤',
        text: result.message,
        confirmButtonText: '確定',
      });
      return false;
    },
    async minus(v) {
      if (this.checkingStock) return;
      /*  */
      if (this.itemsArray[v].qty !== 1) {
        this.itemsArray[v].qty -= 1;
        this.itemsArray[v].total = this.getTotal(this.itemsArray[v]);
        this.itemsArray[v].beforeDiscountTotal = this.itemsArray[v].total;
        if (this.itemsArray[v].price) {
          this.itemsArray[v].originTotal = this.getOriginTotal(this.itemsArray[v]);
        }
      } else {
        this.itemsArray[v].qty = 1;
      }
      this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
      const { status, message } = await this.checkEnoughStock(this.itemsArray[v]);
      if (status) {
        this.addCart(this.itemsArray[v]);
      } else {
        // this.plus(v);
        this.$customSWAL({
          icon: 'info',
          title: message,
        });
      }
    },
    async plus(v) {
      if (this.checkingStock) return;
      // const defaultItemArray = cloneDeep(this.itemsArray);

      const shopItem = cloneDeep(this.itemsArray[v]);
      /*  */
      shopItem.qty += 1;
      shopItem.total = this.getTotal(shopItem);
      shopItem.beforeDiscountTotal = shopItem.total;
      if (shopItem.price) {
        shopItem.originTotal = this.getOriginTotal(shopItem);
      }
      const { status, message } = await this.checkEnoughStock(shopItem);
      if (status) {
        this.$set(this.itemsArray, v, shopItem);
        // this.itemsArray[v] = shopItem;
        this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
        this.addCart(this.itemsArray[v]);
      } else {
        this.$customSWAL({
          icon: 'info',
          title: message,
        });
        // this.itemsArray = defaultItemArray;
      }
    },
    getOriginTotal(item) {
      let total = parseInt(item.price, 10)
        * parseInt(item.qty, 10);
      if (item.type === '出租品') {
        total *= parseInt(item.days, 10);
      }
      return total;
    },
    getTotal(item) {
      let total = parseInt(item.sell_price, 10)
      * parseInt(item.qty, 10);
      if (item.type === '出租品') {
        total *= parseInt(item.days, 10);
      }
      return total;
    },
    async checkEnoughStock(product) {
      this.checkingStock = true;
      const { status, message } = await requestApi('cart.checkEnoughStock', {
        abroad_start: product.abroad_start,
        abroad_end: product.abroad_end,
        product_id: product.product_id,
        qty: product.qty,
        code: product.code || null,
      });
      this.checkingStock = false;
      return { status, message, product };
    },
    async addCart(product) {
      const formData = {
        member_id: this.user.id,
      };
      if (product.type === '出租品' || product.category === 'SIM卡' || product.category === 'eSIM') {
        formData.abroad_start = product.abroad_start;
        formData.abroad_end = product.abroad_end;
        formData.days = product.days;
      } else {
        formData.color_id = product.color_id;
      }
      formData.total = product.total;
      formData.product_id = product.product_id;
      formData.price = product.price;
      formData.sell_price = product.sell_price;
      formData.qty = product.qty;

      const result = await requestApi('cart.addCart', formData);
      if (!result.status) {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: result.message,
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.back();
          },
        });
      } else {
      /* 如果數量異動,此值改為true,並且在cartPlus重新取得加購品資料 */
        this.qtyChange = true;
      }
    },
    putOrderData() {
      const order = JSON.parse(localStorage.getItem('order'));
      if (order) {
        // eslint-disable-next-line
        const { flight_abroad, take_option_id, return_option_id, take_way_id, return_way_id } = order;
        this.cartForm = {
          flight_abroad,
          take_option_id,
          return_option_id,
          take_way_id,
          return_way_id,
        };
      } else {
        localStorage.setItem('order', JSON.stringify(this.cartForm));
      }
    },
    async getData() {
      this.actionHandleCartLoading(1);
      // eslint-disable-next-line
      const member_id = this.user.id;
      const result = await requestApi('cart.index', { member_id });

      const eventList = result.data?.map(({ id }) => requestApi('cart.getPromotionEvent', { member_id, cart_id: id })) || [];
      const eventResult = (await Promise.all(eventList)).filter(({ data }) => data);

      this.actionHandleCartLoading(-1);
      if (result.status) {
        this.itemsArray = await result.data
          .map((item) => {
            const { data } = eventResult
              ?.find(({ data: eventData }) => eventData.cart_id === item.id) || {};

            return {
              ...item,
              beforeDiscountTotal: item?.total ?? 0,
              plusTotal: 0,
              originTotal: item?.price ? this.getOriginTotal(item) : 0,
              total: Number(data?.discount) > 0
                ? item.total - Number(data?.discount) : item.total ?? 0,
              discountData: {
                type: data?.type ?? 1,
                coupon: {
                  value: null,
                  result: Number(data?.type) === 2,
                  message: Number(data?.type) === 2 ? data?.discount_coupon_name : null,
                  data: null,
                },
                birthDay: {
                  value: null,
                  result: Number(data?.type) === 3,
                  message: Number(data?.type) === 3 ? data?.birthday_coupon_name : null,
                  data: null,
                },
                discount: {
                  code: Number(data?.type) === 1 ? data?.promotion_code : item?.code ?? null,
                  number: Number(data?.type) === 1 ? data?.coupon_no : null,
                  result: Number(data?.type) === 1,
                  message: Number(data?.type) === 1 ? data?.promotion_name : null,
                  data: null,
                },
              },
            };
          })
          // eslint-disable-next-line no-unused-vars
          .reduce((res, item, idx) => {
            this.$set(res, idx, item);
            // res.push(item);
            return res;
          }, []);

        this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
        this.checkCurrentCarts();
        this.checkCartsHasRent();

        this.setOrderDetail(this.itemsArray);

        /* 結帳金額再折扣 */
        if (localStorage.getItem('discountEventCode')) {
          const code = {
            target: {
              value: localStorage.getItem('discountEventCode'),
            },
          };
          this.checkDiscountEvent(code);
        }
      } else if (result.message === '購物車為空') {
        this.$router.push({
          name: 'CartEmpty',
        });
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: result.message,
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.back();
          },
        });
      }
    },
    checkCurrentCarts() {
      const checkProducts = new Promise((resolve) => {
        if (this.itemsArray.length > 0) {
          this.itemsArray.forEach((item) => {
            if (item.type === '出租品' || item.category === 'SIM卡' || item.category === 'eSIM') {
              resolve(1);
            }
          });
        }
        resolve(2);
      });
      checkProducts.then((value) => {
        localStorage.setItem('currentCartType', value);
        this.currentCartType = value;
      });
    },
    checkCartsHasRent() {
      const checkProducts = new Promise((resolve) => {
        if (this.itemsArray.length > 0) {
          this.itemsArray.forEach((item) => {
            if (item.type === '出租品') {
              resolve(1);
            }
          });
        }
        resolve(2);
      });
      checkProducts.then((value) => {
        localStorage.setItem('cartsHasRent', value);
        this.cartsHasRent = value;
      });
    },
    closeLightBox(submit = false) {
      const index = this.currentLightBoxItemIndex;
      if (submit) {
        let discountResult = 0;
        let dis = 0;
        const data = this.coupons[this.selectCouponIndex];
        const subTotal = Math.round(this.itemsArray[index].total);
        const discountType = data.discount_type;
        if (discountType === 1) {
          dis = data.discount;
        } else {
          discountResult = Math.round(subTotal * ((100 - data.discount) / 100));
          dis = subTotal - discountResult;
        }
        const cart = {
          member_id: this.user.id,
          cart_id: this.itemsArray[index].id,
          discount_type: data.discount_type,
          type: this.type,
          coupon_id: data.coupon_id,
          discount: dis,
        };
        const addPromotionEvent = requestApi('cart.addPromotionEvent', cart);
        addPromotionEvent.then((response) => {
          if (response.status) {
            this.memberCoupons(index, true, data.event_name, data, this.type);
            this.clearLightBox();
          }
        });
      } else {
        const cart = {
          member_id: this.user.id,
          cart_id: this.itemsArray[index].id,
        };
        const addPromotionEvent = requestApi('cart.addPromotionEvent', cart);
        addPromotionEvent.then((response) => {
          if (response.status) {
            const value = {
              target: {
                value: this.type,
              },
            };
            this.resetDiscount(value, this.currentLightBoxItemIndex);
            this.clearLightBox();
          }
        });
      }
    },
    clearLightBox() {
      this.couponLightbox = false;
      this.coupons = [];
      this.currentLightBoxItemIndex = null;
      this.selectCouponIndex = null;
      this.type = null;
    },
    openLightBox(result, index, data, type) {
      this.couponLightbox = result;
      this.currentLightBoxItemIndex = index;
      this.coupons = data;
      this.type = type;
    },
    dateYMD(day) {
      return moment(day).format('YYYY/MM/DD');
    },
    takeWayMethod(id) {
      let name;
      this.takeways.forEach((element) => {
        if (element.id === id) {
          name = element.parent;
        }
      });
      this.setTakeway(name);
      if (name === '宅配' || name === '超取') {
        this.takeDay = moment(this.getCurrentTakeDay).subtract(1, 'd').format('YYYY-MM-DD');
      } else {
        this.takeDay = this.getCurrentTakeDay;
      }
      this.setAbroadAt(this.getCurrentTakeDay);
    },
    checkOrder() {
      const order = JSON.parse(localStorage.getItem('order'));
      const currentCartType = parseInt(localStorage.getItem('currentCartType'), 10);
      const cartsHasRent = parseInt(localStorage.getItem('cartsHasRent'), 10);
      this.currentCartType = currentCartType;
      this.cartsHasRent = cartsHasRent;
      // currentCartType 1:上網工具 2:一般商品
      // cartsHasRent: 1:出租品 2:出售品
      if (order) {
        // 如果是上網工具
        if (currentCartType === 1) {
          // 出租品
          if (cartsHasRent === 1) {
            Object.keys(order).every((key) => {
              if (order[key] === '' || order[key] === null) {
                let name;
                if (key === 'flight_abroad') {
                  name = '航班時間';
                } else if (key === 'take_way_id') {
                  name = '取件方式';
                } else if (key === 'return_way_id') {
                  name = '歸還方式';
                }
                this.$customSWAL({
                  icon: 'error',
                  title: '錯誤',
                  text: `請填寫${name}`,
                  confirmButtonText: '確定',
                  thenFunc: () => {
                    this.$router.back();
                  },
                });
                return false;
              }
              return true;
            });
          } else {
            // 出售品
            Object.keys(order).every((key) => {
              if (key !== 'return_option_id' && key !== 'return_way_id') {
                if ((order[key] === '' || order[key] === null)) {
                  let name;
                  if (key === 'flight_abroad') {
                    name = '航班時間';
                  } else if (key === 'take_way_id') {
                    name = '取件方式';
                  }
                  this.$customSWAL({
                    icon: 'error',
                    title: '錯誤',
                    text: `請填寫${name}`,
                    confirmButtonText: '確定',
                    thenFunc: () => {
                      this.$router.back();
                    },
                  });
                  return false;
                }
              }
              return true;
            });
          }
        } else if (currentCartType === 2) {
          if (!order.take_option_id || !order.take_way_id) {
            this.$customSWAL({
              icon: 'error',
              title: '錯誤',
              text: '請填寫取件方式',
              confirmButtonText: '確定',
              thenFunc: () => {
                this.$router.back();
              },
            });
          }
        } else {
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: '發生例外錯誤:無購物車商品資訊',
            confirmButtonText: '確定',
            thenFunc: () => {
              this.$router.push('/');
            },
          });
        }
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: '請填寫商品取件方式',
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.back();
          },
        });
      }
      this.putOrderData();
    },
    async getTakeWayByProducts(ids) {
      const { status, message, data } = await requestApi('req.getTakeWayByProducts', { ids });

      if (status) {
        const options = [];
        Object.keys(data).forEach((group) => {
          options.push({
            text: data[group].name,
            value: data[group].id,
            disable: true,
          });
          Object.keys(data[group].options).forEach((option) => {
            options.push({
              text: data[group].options[option].option,
              id: data[group].options[option].id,
              shipping_fee: data[group].options[option].shipping_fee,
              parent: data[group].name,
              parent_id: data[group].id,
              disable: false,
            });
          });
        });
        this.takeways = options;
        localStorage.setItem(
          'takeways',
          JSON.stringify(this.takeways),
        );
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: message,
          confirmButtonText: '確定',
        });
      }
    },
    async getReturnWayByProducts(ids) {
      const { status, message, data } = await requestApi('req.getReturnWayByProducts', { ids });

      if (status) {
        const options = [];
        Object.keys(data).forEach((group) => {
          options.push({
            text: data[group].name,
            value: data[group].id,
            disable: true,
          });
          Object.keys(data[group].options).forEach((option) => {
            options.push({
              text: data[group].options[option].option,
              id: data[group].options[option].id,
              parent: data[group].name,
              parent_id: data[group].id,
              disable: false,
            });
          });
        });
        this.returnways = options;
      } else if (data) {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: message,
          confirmButtonText: '確定',
        });
      }
    },
    async checkFreightEvent() {
      if (this.countIng) return;
      this.actionHandleCartLoading(1);
      if (this.getBeforeDeliveryPrice > 0) {
        this.countIng = true;

        const total = this.getBeforeDeliveryPrice;
        const { status, message, data } = await requestApi('promotion.checkFreightEvent', { total_amount: total });
        this.delivery = {
          data,
          message,
          status,
        };
        this.setTotalPriceAndCount(this.getcurrentTotalPriceAndCount);
      }
      this.countIng = false;
      this.actionHandleCartLoading(-1);
    },
    async getFilter(ids) {
      await Promise.all([
        this.getTakeWayByProducts(ids),
        this.getReturnWayByProducts(ids),
      ]);

      if (this.cartForm.take_option_id) {
        this.takeWayMethod(this.cartForm.take_option_id);
      }
      if (this.cartForm.return_option_id) {
        const payload = {
          target: {
            value: this.cartForm.return_option_id,
          },
        };
        this.returnTakeWays(payload);
      }
      this.setBackhomeAt(this.getReturnTakeDay);
    },
  },
  async mounted() {
    if (this.isLogin) {
      await this.getData();
      await this.checkStock();
      if (this.$route.name === 'CartDetail') {
        await this.checkOrder();
      }
    }
    if (this.ifPrev) {
      this.putOrderData();
    }
    this.init = true;

    this.updateCanCartToDetail(
      {
        cartForm: this.cartForm,
        cartsHasRent: this.cartsHasRent,
      },
    );
  },
};
</script>
